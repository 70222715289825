import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCuMNfnATNTbmd51NGZGFI7JTxsTndjZyA",
  authDomain: "fullchat-f5f97.firebaseapp.com",
  projectId: "fullchat-f5f97",
  storageBucket: "fullchat-f5f97.appspot.com",
  messagingSenderId: "449484152246",
  appId: "1:449484152246:web:e4e06247c9aff2cef17517",
  measurementId: "G-M84RLHTKH8"
};

// Inicializando o Firebase
const app = initializeApp(firebaseConfig);

// Inicializando o Firestore
const db = getFirestore(app);

export { db };