
import * as React from 'react';

import { useState } from "react"

import './App.css';

import { db } from "./Connections"
import { collection, getDocs, addDoc } from "firebase/firestore";

import { FaRegComments } from "react-icons/fa";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { TextField } from '@mui/material';


import logo from '../src/img/logo.png'
import produto from '../src/img/produtos.png'
import insta from '../src/img/insta.png'


    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '80%',
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
    };


function App() {

  const [open, setOpen] = React.useState(false);
  const [load, setLoad] = React.useState(false)

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  const [nome,setNome] = useState('')
  const [contato, setContato] = useState('')
  const [feed,setFeed] = useState('')
  const [loading, setLoading] = useState(false)


    // Data
  let timeElapsed = Date.now();
  let today = new Date(timeElapsed).toLocaleString();

  async function irMessagens(){

      if( nome == '' || feed == ''){
        alert('E necessário preencher todos os campos!')
        return
      }
    
        setLoading(true)

        try {
          const docRef = await addDoc(collection(db, "feedbacks"), {
            name: nome,
            contato: contato,
            feed: feed,
            data: today
          });
          alert('Breve entraremos em contato')
          setNome('')
          setContato('')
          setFeed('')
          setLoading(false)
          console.log("Document written with ID: ", docRef.id);

        } catch (e) {
          alert('Deu algum erro')
          setLoading(false)
          console.error("Error adding document: ", e);
        }
      };
    
 


   return (
    <div className="App">
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2" style={{color:'gray'}}>
              Fale conosco
            </Typography>

            {/* <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
            </Typography> */}

            <TextField id="outlined-basic" label="seu nome" variant="outlined" value={nome} onChange={(e) => setNome (e.target.value)} style={{width:'95%'}} />
            <br></br>
            <br></br>
            <TextField id="outlined-basic" label="telefone ou email" variant="outlined" value={contato} onChange={(e) => setContato (e.target.value)}  style={{width:'95%'}} />
            <br></br>
            <br></br>
            <TextField id="outlined-basic" label="sua mensagem" variant="outlined" value={feed} onChange={(e) => setFeed (e.target.value)}  style={{width:'95%'}} />
            <br></br>


              {loading == true ? (
                <>
                  <span style={{color:"green"}}>Enviando mensagem...</span>
                </>
              ):
               <Button onClick={irMessagens}>Enviar...</Button>
              }

          </Box>

      </Modal>
      <div className='divImg'>
        <img src={logo} />



        <div className='divProduto'>
          <img src={produto} />
        </div>

        <section>
          <h4>MAIS VISIBILIDADE (Modelos)</h4>
        </section>

        <ul>
          <li > <a style={{fontSize: 18}} href='https://fastcar-locacao.vercel.app/' target='_blank'> Sistemas para locação de veículos <small className='new'>new</small> </a > </li>
          <li > <a style={{fontSize: 18}}href='https://systemchamados.netlify.app/' target='_blank'> Sistemas para chamados <small className='new'>new</small> </a > </li>
          <li > <a style={{fontSize: 18}}href='https://sexshopproducts.netlify.app' target='_blank'> Modelo de E-comercer </a > </li>
          {/* <li > <a href='https://iperium-sex-shop.netlify.app/' target='_blank'> Modelo de E-comercer </a > - <small className='new'>Site Ativo!</small> </li> */}
          <li><a  style={{fontSize: 18}} href='https://cardapio-dig.netlify.app/' target='_blank'> Cardápio / Delivery Digital  </a> </li>
          {/* <li><a href='https://cardapio-dig.netlify.app/' target='_blank'> Cardápio / Delivery Digital  </a> - <small className='new'>new</small> </li> */}
          <li><a style={{fontSize: 18}} href='https://vitrine-cars.netlify.app' target='_blank'> Vitrine de Carros </a></li>
          <li><a style={{fontSize: 18}} href='https://catalago.netlify.app' target='_blank'>  Catálago Digital </a></li>
        </ul>

        <section>
          <h4>JOGOS E DIVERSÃO (Modelos) </h4>
        </section>

        <ul>
          <li><a  style={{fontSize: 18}} href='https://ranking-of-thrones.netlify.app/' target='_blank'> Sistema de votação com Rank - <small className='new'>new</small> <small>"Baseado na série Game Of Thrones" </small> </a></li>
          <li><a  style={{fontSize: 18}} href='https://quizz-dev.netlify.app' target='_blank'> Crie seu Quiz </a></li>
          <li><a  style={{fontSize: 18}} href='https://dragonmemory.netlify.app' target='_blank'> Crie um Jogo da Memória </a></li>
        </ul>


        <div className='divPersonalizar'>
          <small>Todos os modelos podem ser personalizados!</small>
        </div>

        <div className='divContato'>
          <div className='contatoTitulo'>
              <Button style={{color:'#fff', fontWeight:'bolder', fontSize:20}} onClick={handleOpen}>clique aqui!</Button>
          </div>


          <div className='contatoImg'>
            {/* <a href={`//api.whatsapp.com/send?phone=+5588992006856&text=Ol%C3%A1%20gostaria%20de%20saber%20mais%20sobre%20seus%20servi%C3%A7os`} target='blank'> <img style={{ width: '80px' }} src={whats} /></a> */}
            <a href="https://www.instagram.com/f.fsolutions/" target='blank'> <img style={{ width: '80px' }} src={insta} /></a>

          </div>
        </div>
      </div>

    </div>
  );
}

export default App;


